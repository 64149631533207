import './Footer.scss'
import React, { createRef, FC, useEffect } from 'react'
import { reachGoal } from '../../misc/reachGoal'
import useOnEnter from '../../misc/useOnEnter'


const Footer: FC = () => {
    const SOCIALS: Array<{ key: string, title: string, href: string }> = [
        {
            key: 'vkontakte',
            title: 'ВКонтакте',
            href: 'https://vk.com/aviasales',
        },
        {
            key: 'telegram',
            title: 'Telegram',
            href: 'https://t.me/aviasales',
        },
        {
            key: 'twitter',
            title: 'Twitter',
            href: 'https://twitter.com/aviasales',
        },
        {
            key: 'tiktok',
            title: 'TikTok',
            href: 'https://www.tiktok.com/@aviasales',
        },
        {
            key: 'dzen',
            title: 'Дзен',
            href: 'https://dzen.ru/aviasales',
        },
        {
            key: 'viber',
            title: 'Viber',
            href: 'https://invite.viber.com/?g2=AQAtX3IQDoKIAkjNZmzOMFaqi9OHuUKPrggGpt4kfnukhlsf0gueU8hnc6dLdcqt',
        },
        {
            key: 'youtube',
            title: 'YouTube',
            href: 'https://www.youtube.com/aviasales',
        },
        {
            key: 'psgr',
            title: 'Журнал',
            href: 'https://www.aviasales.ru/psgr?utm_content=blog_psgr&utm_source=aviasales&utm_campaign=psgr&utm_medium=podpiskana',
        },
    ]

    const MENU: Array<{ title: string, href: string }> = [
        { title: 'О компании', href: 'https://www.aviasales.ru/about' },
        { title: 'Партнёрская программа', href:  'https://www.aviasales.ru/affiliateprogram' },
        { title: 'Реклама', href:  'https://ad.aviasales.ru' },
        { title: 'Вакансии', href:  'https://www.aviasales.ru/about/work' },
        { title: 'Помощь', href:  'https://www.aviasales.ru/faq' },
        { title: 'Правила', href:  'https://www.aviasales.ru/terms-of-use' },
    ]
    const screenRef = createRef<HTMLDivElement>()
    const isScreenInViewport = useOnEnter(screenRef, 1)
    useEffect(() => {
        if (isScreenInViewport) {
            reachGoal('scroll_footer', {}, 'scrolling', 'footer')
        }
    }, [ screenRef, isScreenInViewport ])

    return (
        <footer ref={screenRef} className='footer'>
            <a href='/' className='footer__logo'>b2b.aviasales.ru</a>
            <div className='footer__socials'>
                {SOCIALS.map((item) => (
                    <a
                        key={item.key}
                        target='_blank'
                        href={item.href}
                        rel='noreferrer noopener'
                        onClick={() => reachGoal(`clicks_${item.key}`, {}, 'clicks', item.key )}
                        className={`footer__social footer__social_${item.key}`}
                    >
                        <span className={`footer__social-icon footer__social-icon_${item.key}`} />
                        <span className='footer__social-text'>{item.title}</span>
                    </a>
                ))}
            </div>
            <nav className='footer__menu'>
                {MENU.map((item) => (
                    <a
                        target='_blank'
                        key={item.href}
                        href={item.href}
                        rel='noreferrer noopener'
                        className='footer__menu-item'
                        onClick={() => reachGoal('clicks_aviasales', {}, 'clicks', 'aviasales')}
                    >
                        {item.title}
                    </a>
                ))}
            </nav>
            <p className='footer__copyright'>© 2007–{(new Date()).getFullYear()}, Авиасейлс — дешевые авиабилеты</p>
        </footer>
    )
}

export default Footer


