import React, { createRef, FC, useEffect } from 'react'

const Ticker: FC = () => {
    const tickerRef = createRef<HTMLDivElement>()

    useEffect(() => {
        const onScroll = () => {
            if (!tickerRef.current) return
            const clientRect = tickerRef.current.getBoundingClientRect()
            const factor = (((clientRect.top + clientRect.height / 2) / window.innerHeight) - 0.5) * 2 - 1

            tickerRef.current.style.transform = `translateX(${factor * 7}vh)`
        }

        window.addEventListener('scroll', onScroll)

        return () => window.removeEventListener('scroll', onScroll)
    }, [ tickerRef ] )

    return (
        <div className='likes__ticker'>
            <div ref={tickerRef} className='likes__ticker-content'>
                Людям вроде всё нравится | Людям вроде всё нравится | Людям вроде всё нравится | Людям вроде всё нравится | Людям вроде всё нравится
            </div>
        </div>
    )
}

export default React.memo(Ticker)
