import './LettersScreen.sass'
import React, { createRef, FC, useEffect, useState } from 'react'
import Button from '../Button'
import useOnEnter, { checkRefInViewport } from '../../misc/useOnEnter'
import Form from '../Form'
import { reachGoal } from '../../misc/reachGoal'

const LettersScreen: FC = () => {
    const [ btnText, setBtnText ] = useState('Лов лю')

    const screenRef = createRef<HTMLDivElement>()
    const interactiveLettersRef = createRef<HTMLDivElement>()
    const interactiveLettersSecondRef = createRef<HTMLDivElement>()

    const isEntered = useOnEnter(interactiveLettersRef, 0.8)

    useEffect(() => {
        // console.log(isEntered);
        // const letters = document.querySelectorAll('.letters__letter')
        // if (isEntered && interactiveLettersRef.current && interactiveLettersSecondRef.current) {
        //     const clientRect = interactiveLettersRef.current.getBoundingClientRect()
        //     const factor = (((clientRect.top + clientRect.height / 2) / window.innerHeight) - 0.5) * 2
        //     console.log(factor);
        //     interactiveLettersRef.current.classList.add('letters__fly')
        //     interactiveLettersSecondRef.current.classList.add('letters__fly')
        //     letters.forEach((letter, index) => {
        //             letter.classList.add('letters__active-animation')

        //     }
        //     )
        // }

        const onScroll = () => {
            if (checkRefInViewport(screenRef)) reachGoal('scroll_screen3', {}, 'scrolling', 'screen3')
            if (interactiveLettersRef.current && interactiveLettersSecondRef.current) {
                const clientRect = interactiveLettersRef.current.getBoundingClientRect()
                const factor = (((clientRect.top + clientRect.height / 2) / window.innerHeight) - 0.5) * 2
                interactiveLettersSecondRef.current.style.transform = `translateX(${(factor - 1.1) * -95}vw)`
                interactiveLettersRef.current.style.transform = `translateX(${(factor - 1.1) * -95}vw)`
            }
        }
        window.addEventListener('scroll', onScroll)

        return () => window.removeEventListener('scroll', onScroll)


    }, [interactiveLettersRef, interactiveLettersSecondRef, screenRef])


    return (
        <section ref={screenRef} className='letters'>
            <div ref={interactiveLettersRef} className="letters__animation-back">
                <div className="letters__letter letters__active-animation_letter letters__first" />
                <div className="letters__letter letters__active-animation_letter letters__second" />
                <div className="letters__letter letters__active-animation_letter letters__third" />
                <div className="letters__letter letters__active-animation_letter letters__ninth" />
            </div>
            <div className='letters__title'>Иногда мы будем отправлять письма</div>
            <div  className='letters__subtitle'>(которые с любовью напишем лично для вас)</div>
            <div className='letters__inner'>
                <div className='letters__form'>
                        <div className='letters__form-title'>Начните ловить письма прямо сейчас</div>
                        <Form screen='scroll_screen3' onSubmit={() => setBtnText('Отправлено')}>
                            <Button type='submit' className='letters__form-btn' color='beauty'>{btnText}</Button>
                        </Form>
                </div>
            </div>

            <div ref={interactiveLettersSecondRef} className='letters__animation-front'>
                <div className="letters__letter letters__active-animation_letter letters__fifth" />
                <div className="letters__letter letters__active-animation_letter letters__sixth" />
                <div className="letters__letter letters__active-animation_letter letters__eighth" />
            </div>
        </section>
    )
}

export default LettersScreen
