import './Button.sass'
import React, { FC } from 'react'

interface IButtonProps {
    href?: string
    className?: string
    component?: 'button' | 'a'
    type?: 'button' | 'submit'
    color?: 'primary' | 'accent' | 'beauty'
    variant?: 'outlined' | 'contained'
    children?: any
    onClick?: (e: React.MouseEvent) => void
    disabled?: boolean
}

const Button: FC<IButtonProps> = ({
    component,
    className,
    variant,
    onClick,
    color,
    type = 'button',
    disabled = false,
    href,
    children,
}) => {
    const TagName = component || 'button'
    if (TagName === 'button')
        return (
            <TagName
                type={type}
                onClick={onClick}
                disabled={disabled}
                className={`button ${variant || 'contained'} ${color || 'accent'} ${className || ''}`}
            >
                {children}
            </TagName>
        )
    else
        return (
            <TagName
                href={href}
                onClick={onClick}
                className={`button ${variant || 'contained'} ${color || 'accent'} ${className || ''}`}
            >
                {children}
            </TagName>
        )
}

export default Button
