import './TicketsScreen.sass'
import React, { createRef, FC, useEffect } from 'react'
import DesktopTickets from './DesktopTickets'
import { reachGoal } from '../../misc/reachGoal'
import useOnEnter from '../../misc/useOnEnter'

const TicketsScreen: FC = () => {
    const screenRef = createRef<HTMLInputElement>()
    const canvasRef = createRef<HTMLCanvasElement>()
    const isScreenInViewport = useOnEnter(screenRef)

    useEffect(() => {
        if (isScreenInViewport) {
            reachGoal('scroll_screen4', {}, 'scrolling', 'screen4')
        }
    }, [isScreenInViewport])

    useEffect(() => {
        if (!canvasRef.current) return
        const tickets = new DesktopTickets(canvasRef.current)
        tickets.init().then()
    }, [])

    return (
        <section className='tickets' ref={screenRef}>
            <h2 className='tickets__title'>И подборки с билетами</h2>
            <div className='tickets__subtitle'>Из города, в котором вы живёте. Например, из Москвы в Питер авиабилеты от 2 450 ₽</div>
            <canvas ref={canvasRef} className='tickets__canvas' />
        </section>
    )
}

export default React.memo(TicketsScreen)


