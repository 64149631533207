import { RefObject, useEffect, useState } from 'react'

export default function useOnEnter(ref: RefObject<HTMLElement>, accuracy = 0.2): boolean {
    const [ isEntered, setIsEntered ] = useState(false)

    const onScroll = () => {
        if (!ref.current) return
        checkRefInView()
    }

    const checkRefInView = (): boolean => {
        if (!ref.current) return false
        const clientRect = ref.current.getBoundingClientRect()
        const factor = (((clientRect.top + clientRect.height / 2) / window.innerHeight) - 0.5) * 2
        if (Math.abs(factor) <= accuracy) {
            setIsEntered(true)
            window.removeEventListener('scroll', onScroll)
            return true
        }

        return false
    }

    useEffect(() => {
        if (checkRefInView()) return

        window.addEventListener('scroll', onScroll)

        return () =>  window.removeEventListener('scroll', onScroll)
    }, [ ref ])

    return isEntered
}

export function checkRefInViewport(ref: RefObject<HTMLElement>, accuracy = 0.2): boolean {
    if (!ref.current) return false
    const clientRect = ref.current.getBoundingClientRect()
    const factor = (((clientRect.top + clientRect.height / 2) / window.innerHeight) - 0.5) * 2

    return Math.abs(factor) <= accuracy
}
