import './App.sass'
import React, { FC } from 'react'
import TitleScreen from './components/TitleScreen'
import SecondScreen from './components/SecondScreen'
import LettersScreen from './components/LettersScreen'
import TicketsScreen from './components/TicketsScreen'
import PeopleLikesScreen from './components/PeopleLikesScreen'
import FinalForm from './components/FinalForm'
import Footer from './components/Footer'

const App: FC = () => {
  return (
      <>
        <TitleScreen />
        <SecondScreen />
        <LettersScreen />
        <TicketsScreen />
        <PeopleLikesScreen />
        <FinalForm />
        <Footer />
      </>
  )
}

export default App
