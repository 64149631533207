import TicketImage from '../../assets/ticket_1.png'
import TicketImageRetina from '../../assets/ticket_1@2x.png'


const IMAGE_PRESETS: { [key: string]: Omit<ImagesStateItem, 'img'> } = {
    1: { x: -600, y: 200, speed: 0.7, dir: -1, width: 447, height: 235 },
    2: { x: 724, y: -34, speed: 0.7, dir: 1, width: 447, height: 235 },
    3: { x: 242, y: -72, speed: 0.5, dir: -1, width: 447, height: 235  },
    4: { x: -356, y: 128, speed: 0.8, dir: -1, width: 408, height: 215  },
    5: { x: -90, y: 20, speed: 1, dir: -1, width: 408, height: 215  },
    6: { x: 158, y: 151, speed: 1.4, dir: -1, width: 768, height: 405  },
    7: { x: 554, y: 45, speed: 1.8, dir: -1, width: 294, height: 154  },
    8: { x: 676, y: 150, speed: 1.8, dir: -1, width: 408, height: 215  },
    9: { x: -609, y: -37, speed: 2, dir: -1, width: 447, height: 235  },
    10: { x: -395, y: -106, speed: 2.4, dir: -1, width: 296, height: 155  },
}

const renderOrder = Object.keys(IMAGE_PRESETS).map(Number).sort((left, right) => right - left)
const vhFactory = (height: number) => (value: number) => height / 760 * value

const PARALLAX_AMPLITUDE = 20

export default class DesktopTickets {
    private w = 0
    private h = 0
    private vh = vhFactory(0)
    private cursor = { x: 0, y: 0 }
    private prevRenderAt = Date.now()
    private readonly ctx: CanvasRenderingContext2D
    private readonly imagesState: { [key: string]: ImagesStateItem } = {}
    private deviceOrientationInitialState: { beta: number, gamma: number } | null = null

    constructor(private readonly htmlCanvas: HTMLCanvasElement) {
        const ctx = htmlCanvas.getContext('2d')
        if (!ctx) throw new Error('ctx is not defined')
        this.ctx = ctx

        this.resize()
        window.addEventListener('resize', this.resize)
        window.addEventListener('mousemove', this.onMouseMove)
        window.addEventListener('deviceorientation', this.onDeviceOrientationChange)
    }

    get internalPixelRatio(): number {
        return Math.min(Math.round((window.devicePixelRatio || 1)), 2)
    }
    private preload(): Promise<HTMLImageElement> {
        const imageUrl = this.internalPixelRatio > 1 ? TicketImageRetina : TicketImage;
        
        return new Promise<HTMLImageElement>((resolve) => {
            const img = new Image();
            img.addEventListener('load', () => {
                resolve(img);
            });
            img.src = imageUrl;
        });
    }

    public init(): Promise<void> {
        return this.preload().then((images) => {
            this.processImages(images)
            this.render()
        })
    }

    private processImages(image: HTMLImageElement): void {
         Object.keys(IMAGE_PRESETS).forEach((imageName) => {
            const preset = IMAGE_PRESETS[imageName];
            this.imagesState[imageName] = {
                img: image,
                x: preset.x,
                y: preset.y,
                dir: preset.dir,
                speed: preset.speed,
                width: preset.width,
                height: preset.height,
            };
        });

        console.log(IMAGE_PRESETS);
    }

    private onDeviceOrientationChange = (e: DeviceOrientationEvent): void=> {
            if (!e.beta || !e.gamma || !e.alpha) return
            if (!this.deviceOrientationInitialState) this.deviceOrientationInitialState = { beta: e.beta, gamma: e.gamma }
            this.cursor.x = (e.gamma - this.deviceOrientationInitialState.gamma) / 20
            this.cursor.y = (e.beta - this.deviceOrientationInitialState.beta) / 20

    }

    private onMouseMove = (e: MouseEvent): void => {
        this.cursor.x = ((e.clientX / window.innerWidth) - 0.5) * 2
        this.cursor.y = ((e.clientY / window.innerWidth) - 0.5) * 2
    }

    private render = (): void => {
        this.prevRenderAt = Date.now()
        this.ctx.clearRect(0, 0, this.w, this.h)

        renderOrder.forEach((imageKey) => {
            const { x, y, img, speed } = this.imagesState[imageKey];
            const preset = IMAGE_PRESETS[imageKey];
            const iW = this.vh(preset.width);
            const iH = this.vh(preset.height);
        
            this.ctx.drawImage(
                img,
                ((this.w / 2 - iW / 2) + this.vh(x)) + this.vh(PARALLAX_AMPLITUDE * this.cursor.x) * speed + (Math.sin(this.prevRenderAt * 0.0005) * (PARALLAX_AMPLITUDE * 3 * speed)),
                ((this.h / 2 - iH / 2) + this.vh(y)) + this.vh(PARALLAX_AMPLITUDE * this.cursor.y) * speed,
                iW,
                iH
            );
        });

        requestAnimationFrame(this.render)
    }

    private resize = () => {
        const devicePixelRatio = this.internalPixelRatio
        const { width, height } = this.htmlCanvas.getBoundingClientRect()

        this.vh = vhFactory(height * devicePixelRatio)
        this.htmlCanvas.width = this.w = width * devicePixelRatio
        this.htmlCanvas.height = this.h = height * devicePixelRatio
    }
}

interface ImagesStateItem {
    x: number
    y: number
    dir: number
    speed: number
    img: HTMLImageElement
    width: number
    height: number
}