import './FinalForm.sass'
import React, { createRef, FC, useCallback, useEffect, useMemo, useState } from 'react'
import Form from '../Form'
import { reachGoal } from '../../misc/reachGoal'
import useOnEnter from '../../misc/useOnEnter'

const GOOGLE_URL = 'https://mail.google.com/mail/u/0/#search/in%3Aanywhere+subject%3A%22%D0%A1%D0%BA%D0%B0%D0%B6%D0%B5%D1%82%D0%B5+%D0%94%D0%B0%22+from%3Asubscribe%40aviasales.ru'


const FinalForm: FC = () => {
    const screenRef = createRef<HTMLDivElement>()
    const [ emailState, setEmailState ] = useState(false)
    const [isGmail, setIsGmail] = useState(false)
    const [ formHasSent, setFormHasSent ] = useState(false)
    const catRef = createRef<HTMLDivElement>()
    const bubleRef = createRef<HTMLDivElement>()

    const bubbleText = useMemo(() => {

        console.log(isGmail);
        return isGmail
            ? <p className='final-form__buble_text'>Кайф! А теперь проверь почту и&nbsp;<a href={GOOGLE_URL} target="_blank" rel="noreferrer noopener">подтверди подписку</a></p>
            : <p className='final-form__buble_text'>Кайф! А теперь проверь почту и подтверди подписку</p>
    }, [isGmail])

    const isScreenInViewport = useOnEnter(screenRef, 0.3)
    const onSubmit = useCallback(() => {
        setFormHasSent(true)
        reachGoal('visibility_form_thanks', {}, 'visibility', 'form_thanks')

        if (catRef.current) {
            catRef.current.classList.add('final-form__cat_final')
        }

        if (bubleRef.current) {
            bubleRef.current.style.opacity = '1'
            bubleRef.current.style.visibility = 'visible'
        }
    }, [ catRef, bubleRef ])

    useEffect(() => {
        if (isScreenInViewport) {
            reachGoal('scroll_screen6', {}, 'scrolling', 'screen6')
        }
    }, [ screenRef, isScreenInViewport ])

    useEffect(() => {
        if (emailState && catRef.current) {
            catRef.current.classList.add('final-form__cat_first')
        }
        if (!emailState && catRef.current) {
            catRef.current.classList.remove('final-form__cat_first')
        }
    }, [catRef, emailState])

    return (
        <section ref={screenRef} className={`final-form ${formHasSent ? 'success' : ''}`}>
            <div className='final-form__title'>Последний раз спрашиваем… Хотите письма?</div>
            <div className='final-form__subtitle'>если не хотите — тут уж мы ничего не поделаем</div>
            <div className='final-form__outer'>
                <div className='final-form__inner'>
                    {!formHasSent && (<div className='final-form__form'>
                        <Form setIsGmail={setIsGmail} setEmailState={setEmailState} screen='scroll_screen6' onSubmit={onSubmit} />
                        <div className='final-form__disclaimer'>Нажимая «Подписаться», вы соглашаетесь с <a rel='noreferrer noopener' target='_blank' href='https://www.aviasales.ru/terms-of-use'>правилами использования сервиса</a> и <a rel='noreferrer noopener' target='_blank' href='https://www.aviasales.ru/privacy'>обработки персональных данных</a>.</div>
                    </div>)}
                    
                </div>
                <div ref={catRef} className='final-form__cat' />
                <div ref={bubleRef} className='final-form__buble'>
                    {bubbleText}
                </div>
            </div>
        </section>
    )
}

export default FinalForm
