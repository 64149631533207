import './SecondScreen.sass'
import React, { createRef, FC, useEffect } from 'react'
import Topic from './Topic'
import { reachGoal } from '../../misc/reachGoal'
import { checkRefInViewport } from '../../misc/useOnEnter'

const SecondScreen: FC = () => {
    const screenRef = createRef<HTMLDivElement>()
    const interactiveTextRef = createRef<HTMLDivElement>()

    useEffect(() => {
        const onScroll = () => {
            if (checkRefInViewport(screenRef)) reachGoal('scroll_screen2', {}, 'scrolling', 'screen2')
            if (interactiveTextRef.current) {
                const clientRect = interactiveTextRef.current.getBoundingClientRect()
                const factor = (((clientRect.top + clientRect.height / 2) / window.innerHeight) - 0.5) * 2
                interactiveTextRef.current.style.transform = `translateX(${(factor - 0.5) * 200}vw)`
            }
        }
        window.addEventListener('scroll', onScroll)

        return () => window.removeEventListener('scroll', onScroll)
    }, [])

    return (
        <section className='component' ref={screenRef}>
            <div className='component__title'>Что будет дальше?</div>
            <div className='component__subtitle'>Глобально никто не знает, но...</div>
            <div className='component__interactive' ref={interactiveTextRef}>Иногда мы будем присылать письма с пользой</div>
            <div className='component__figure'>
   
            </div>
            <Topic />
        </section>
    )
}

export default SecondScreen
