import Cookies from 'js-cookie'
import queryString from 'querystring'

export default function saveMarkerInCookies() {
    const params = queryString.parse(window.location.search.slice(1))
    if (!params.marker) return
    Cookies.set('marker', params.marker, {
        expires: 30,
        domain: '.aviasales.ru'
    })
    Cookies.set('marker', params.marker, {
        expires: 30,
        path: '/',
    })
}
