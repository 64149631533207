import React, { createRef, FC, useEffect } from 'react'

const topics = [
    'Подборки статей из нашего блога про путешествия ',
    'Псс… ПСЖР ',
    'Шутка? Нет ',
    'Подборки дешёвых билетов ',
    'Предложения с выгодой ',
    'Про то, как переводится «копикэт» ',
]
const currentTopic = {
    text: '',
    cursor: 0,
    backward: false
}

const Topic: FC = () => {
    const topicRef = createRef<HTMLDivElement>()

    useEffect(() => {
        if (!topicRef.current) return
            ;(function animateTopic() {
            if (currentTopic.backward && currentTopic.text.length > 0) {
                currentTopic.text = topics[currentTopic.cursor].slice(0, currentTopic.text.length - 1)
                if (topicRef.current) topicRef.current.textContent = currentTopic.text
                setTimeout(animateTopic, 20)
            } else if (currentTopic.backward && currentTopic.text.length === 0) {
                currentTopic.cursor += 1
                currentTopic.backward = false
                if (currentTopic.cursor > topics.length - 1) currentTopic.cursor = 0
                setTimeout(animateTopic, 50)
            } else if (currentTopic.text.length < topics[currentTopic.cursor].length) {
                currentTopic.text = topics[currentTopic.cursor].slice(0, currentTopic.text.length + 1)
                if (topicRef.current) topicRef.current.textContent = currentTopic.text
                setTimeout(animateTopic, 50)
            } else if (currentTopic.text.length === topics[currentTopic.cursor].length && !currentTopic.backward) {
                setTimeout(() => {
                    currentTopic.backward = true
                    setTimeout(animateTopic, 50)
                }, 1000)
            }
        })()
    }, [topicRef])

    return (
        <div className='component__topic' ref={topicRef} />
    )
}

export default Topic
