import './PeopleLikesScreen.sass'
import React, { createRef, FC, useEffect, useState } from 'react'
import { useSwipeable } from 'react-swipeable'
import Ticker from './Ticker'
import useOnEnter from '../../misc/useOnEnter'
import { reachGoal } from '../../misc/reachGoal'

const REVIEWS = [
    {
        icon: 'twitter',
        user: 'dmgcat',
        comment: 'Самая крутая рассылка на которую я подписан — рассылка @aviasales.'
    },
    {
        icon: 'instagram',
        user: 'vinogradova.ya_',
        comment: 'единственные, от чьей e-mail рассылки я, наверное, не отпишусь никогда 😂'
    },
    {
        icon: 'instagram',
        user: 'igorshuman',
        comment: 'Единственная рассылка, которую открываешь 😅'
    },
    {
        icon: 'instagram',
        user: 'kuchina_mari',
        comment: 'Как можно отписаться от такой рассылки? 💙'
    },
    {
        icon: 'instagram',
        user: 'aliya_zin',
        comment: 'обожаю вашу рассылку @aviasales 😂'
    },
    {
        icon: 'instagram',
        user: 'vlada_smolicheva',
        comment: 'Такую рассылку невозможно пропустить!'
    },
    {
        icon: 'instagram',
        user: 'n.murchalina',
        comment: '@aviasales знает толк в лучших и самых актуальных рассылках 😂'
    },
    {
        icon: 'instagram',
        user: 'lisa.vietta',
        comment: 'Рассылки от @aviasales просто топ🔥😂'
    }
]

const PeopleLikesScreen: FC = () => {
    const containerRef = createRef<HTMLDivElement>()
    const [ activeSlide, setActiveSlide ] = useState(Math.ceil(REVIEWS.length / 2) - 1)
    const [ containerWidth, setContainerWidth ] = useState(0)
    const handlers = useSwipeable({
        onSwiped: (e) => e.dir === 'Left' ? nextSlide() : prevSlide()
    })

    const onSlideView = () => reachGoal('slider_view', {}, 'slider', 'view')
    const isScreenInViewport = useOnEnter(containerRef, 0.4)

    useEffect(() => {
        if (isScreenInViewport) {
            reachGoal('scroll_screen5', {}, 'scrolling', 'screen5')
        }
    }, [ containerRef, isScreenInViewport ])

    useEffect(() => {
        if (containerRef.current) {
            setContainerWidth(containerRef.current.offsetWidth)
        }
        const onResize = () => {
            Promise.resolve().then(() => {
                if (containerRef.current) {
                    setContainerWidth(containerRef.current.offsetWidth)
                }
            })
        }

        window.addEventListener('resize', onResize)
        window.addEventListener('orientationchange', onResize)

        return () => {
            window.removeEventListener('resize', onResize)
            window.removeEventListener('orientationchange', onResize)
        }
    }, [ containerRef ])

    const nextSlide = () => {
        onSlideView()
        let nextSlideIdx = activeSlide + 1

        if (nextSlideIdx >= REVIEWS.length) nextSlideIdx = 0

        setActiveSlide(nextSlideIdx)
    }

    const prevSlide = () => {
        onSlideView()
        let prevSlideIdx = activeSlide - 1

        if (prevSlideIdx < 0) prevSlideIdx = REVIEWS.length - 1

        setActiveSlide(prevSlideIdx)
    }

    return (
        <section className='likes'>
            <Ticker />
            <div className='likes__reviews' {...handlers}>
                <button className='likes__reviews-btn likes__reviews-btn_prev' onClick={prevSlide} />
                    <div className='likes__reviews-container' ref={containerRef}>
                        <div className='likes__reviews-content'>
                            <div className='likes__reviews-items' style={{ transform: `translateX(-${containerWidth * activeSlide}px)` }}>
                                {REVIEWS.map((review, idx) => (
                                    <div className={`likes__reviews-item ${review.icon}`} key={idx}>
                                        <div className='likes__reviews-user'>@{review.user}</div>
                                        <div className='likes__reviews-comment' dangerouslySetInnerHTML={{ __html: review.comment }} />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <nav className='likes__reviews-nav'>
                            {REVIEWS.map((review, idx) => (
                                <button
                                    key={idx}
                                    onClick={() => setActiveSlide(idx)}
                                    className={`likes__reviews-dot likes__reviews-dot_${idx === activeSlide ? 'active' : 'inactive'}`}
                                />
                            ))}
                        </nav>
                    </div>
                <button className='likes__reviews-btn likes__reviews-btn_next' onClick={nextSlide} />
            </div>
        </section>
    )
}

export default PeopleLikesScreen
