import './Form.sass'
import React, { createRef, FC, FormEvent, ChangeEvent, useState, MouseEvent } from 'react'
import Button from '../Button'
import subscribe from '../../misc/subscribe'
import { reachGoal } from '../../misc/reachGoal'

const GOOGLE_URL = 'https://mail.google.com/mail/u/0/#search/in%3Aanywhere+subject%3A%22%D0%A1%D0%BA%D0%B0%D0%B6%D0%B5%D1%82%D0%B5+%D0%94%D0%B0%22+from%3Asubscribe%40aviasales.ru'

interface FormProps {
    screen?: string
    buttonText?: string
    onSubmit?: () => void
    setEmailState?: (email: boolean) => void
    setIsGmail?: (isGmail: boolean) => void
}
const Form: FC<FormProps> = ({ buttonText, onSubmit, children, screen, setEmailState, setIsGmail }) => {
    const emailInputRef = createRef<HTMLInputElement>()
    const [ hasSent, setHasSent ] = useState(false)
    const [ email, setEmail ] = useState('')

    const onFormSubmit = (e: FormEvent): void => {
        e.preventDefault()
        if (hasSent) return
        if (!emailInputRef.current) return
        const isGmail = emailInputRef.current?.value.indexOf('@gmail.com') !== -1
        setIsGmail && setIsGmail(isGmail)
        if (onSubmit) onSubmit()
        subscribe(emailInputRef.current.value).then(() => {
            setHasSent(true)
            reachGoal('forms_formSubmit', { screen }, 'forms', 'formSubmit', screen)
            const label = email.indexOf('@gmail.com') !== -1 ? 'gmail' : 'not_gmail'
            reachGoal('visibility_doi_remind', { screen }, 'visibility', 'doi_remind', label)
            if (onSubmit) onSubmit()
        })
    }
    const onInvalid = (e: FormEvent<HTMLInputElement>) => {
        e.currentTarget.setCustomValidity('')
        if (!e.currentTarget.validity.valid) {
            e.currentTarget.setCustomValidity('Проверь правильность указанного email')
        }
    }

    const onInput = (e: ChangeEvent<HTMLInputElement>) => {
        e.target.setCustomValidity('')
        setEmail(e.currentTarget.value.trim())
        setEmailState && setEmailState(!!e.currentTarget.value.trim())
        reachGoal('forms_formType', { screen }, 'forms', 'formType', screen)
    }

    const onError = () => {
        reachGoal('forms_formError', { screen }, 'forms', 'formError', screen)
    }

    const getConfirmationText = (): string => {
        const isGmail = email.indexOf('@gmail.com') !== -1

        return isGmail
            ? `Хэй! Проверь почту и&nbsp;<a href="${GOOGLE_URL}" target="_blank" rel="noreferrer noopener">подтверди подписку</a>`
            : `Хэй! Проверь почту и&nbsp;подтверди подписку`
    }

    const onCLickBubble = (e: MouseEvent<HTMLDivElement>) => {
        const target: HTMLLinkElement = e.target as any
        if (target.tagName === 'A') {
            reachGoal('clicks_doi_gmail', {}, 'clicks', 'doi_gmail')
        }
    }

    return (
        <>
            <form className={`form ${hasSent ? 'form_sent' : ''}`} onSubmit={onFormSubmit} onInvalid={onError}>
                <input
                    required
                    name='email'
                    type='email'
                    readOnly={hasSent}
                    ref={emailInputRef}
                    onInvalid={onInvalid}
                    autoComplete='email'
                    className='form__input'
                    placeholder='pochtakotika@aviasales.ru'
                    onChange={onInput}
                />
                {children ? children : (
                    <Button type='submit' className='form__btn'>{hasSent ? 'Отправлено' : (buttonText || 'Подписаться')}</Button>
                )}
            </form>
            {!hasSent ? null : (
                <div onClick={onCLickBubble} className="form__confirmation" dangerouslySetInnerHTML={{ __html: getConfirmationText() }} />
            )}
        </>
    )
}

export default Form
