import './TitleScreen.sass'
import React, { createRef, FC, useEffect, useState } from 'react'
import { ReactComponent as Logo } from '../../assets/logo.svg'
import { ReactComponent as Decor } from '../../assets/arrow.svg'
import Form from '../Form'
import isMobile, { isIPad } from '../../misc/isMobile'
import useOnEnter from '../../misc/useOnEnter'
import { reachGoal } from '../../misc/reachGoal'

const bubbles = [ 1, 2, 3, 4 ]

const TitleScreen: FC = () => {
    const screenRef = createRef<HTMLDivElement>()
    const [ parallax, setParallax ] = useState({ x: 0, y: 0 })
    const getTranslate = (factor = 1) => `translate(${parallax.x * factor}vh, ${parallax.y * factor}vh)`
    const requestDeviceorientationPermissions = () => {
        if (DeviceOrientationEvent && typeof DeviceOrientationEvent.requestPermission === 'function') {
            DeviceOrientationEvent.requestPermission().then()
        }
    }
    const isScreenInViewport = useOnEnter(screenRef, 0.4)

    useEffect(() => {
        if (isScreenInViewport) {
            reachGoal('scroll_screen1', {}, 'scrolling', 'screen1')
        }
    }, [isScreenInViewport])

    useEffect(() => {
        if ((isMobile() || isIPad()) && DeviceOrientationEvent) {
            let initialStates: { beta: number, gamma: number } | null
            const calcOrientationParallax = (e: DeviceOrientationEvent) => {
                if (!e.beta || !e.gamma || !e.alpha) return
                if (!initialStates) initialStates = { beta: e.beta, gamma: e.gamma }
                setParallax({
                    x: (e.gamma - initialStates.gamma) / 20,
                    y: (e.beta - initialStates.beta) / 20
                })
            }

            window.addEventListener('deviceorientation', calcOrientationParallax)

            return () => window.removeEventListener('deviceorientation', calcOrientationParallax)
        } else {
            const calcParallax = (e: MouseEvent) => {
                setParallax({
                    x: Math.min(((e.clientX / window.innerWidth) - 0.5) * 2, 1),
                    y: Math.min(((e.clientY / window.innerHeight) - 0.5) * 2, 1)
                })
            }

            setTimeout(() => window.addEventListener('mousemove', calcParallax), 1000)

            return () => window.removeEventListener('mousemove', calcParallax)
        }
    }, [])

    return (
        <section className='title-screen' ref={screenRef}>
            <div className='title-screen__inner'>
                <div className={`title-screen__bubble i1`} onClick={requestDeviceorientationPermissions} style={{ transform: getTranslate(1.4) }} />
                {bubbles.map((i) => (
                    <div key={i} className={`title-screen__bubble i${i}`} onClick={requestDeviceorientationPermissions} style={{ transform: getTranslate(i === 3 ? 1 : i === 1 ? 1 : 0.4) }} />
                ))}
                <header className='title-screen__header'>
                    <a
                        target='_blank'
                        rel='noreferrer noopener'
                        className='title-screen__logo'
                        onClick={() => reachGoal('clicks_logo', {}, 'clicks', 'logo')}
                        href='https://www.aviasales.ru/?utm_source=landing&utm_medium=aviasales&utm_campaign=as_podpiskana'
                    >
                        <Logo className='title-screen__logo-svg' />
                    </a>
                </header>
                <div className='title-screen__content'>
                    <h1 className='title-screen__title'>
                        <strong>Та самая рассылка</strong>
                        <small>которой вам не хватало</small>
                    </h1>
                </div>
                <div className='title-screen__form'>
                    <Form screen='scroll_screen1' />
                </div>
                <div className='title-screen__bg' />
            </div>
        </section>
    )
}

export default TitleScreen
