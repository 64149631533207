
declare const ym: any
declare const ga: any
declare const fbq: any

export function reachGoal(goal: string | Array<string>, params: any = {}, category?: string, action?: string, label?: string): void {
    console.log('goal reached', goal)
    const goalList: Array<string> = Object.prototype.toString.call(goal) === '[object Array]'
        ? goal as any
        : [ goal ]
    for (const goalItem of goalList) {
        try {
            ym(71797930, 'reachGoal', goalItem, params)
        } catch (e) {
            // :(
        }
        try {
            ga.getAll().forEach((tracker: any) => tracker.send('event', category || goalItem, action, label))
        } catch (e) {
            // :(
        }
    }
    try {
        fbq('trackCustom', `podpiskana_${goal}`)
    } catch (e) {
        // :(
    }
}
